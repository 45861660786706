<template>
  <data-table
    :items="reports"
    :headers="headers"
    :custom-columns="['item.project']"
  >
    <template v-slot:custom-render-item.project="{ item }">
      <div class="d-flex align-center">
        <v-icon
          x-small
          class="dot-icon mr-2"
          :class="`success-dot`"
        >
          fiber_manual_record
        </v-icon>
        {{ item.project }}
      </div>
    </template>
  </data-table>
</template>

<script>
import { REPORT_WEEKLY_HEADERS } from '@/views/Report/constants';
import DataTable from '@/components/collections/DataTable/DataTable';

export default {
  name: 'Weekly',
  components: { DataTable },
  data() {
    return {
      reports: [
        {
          id: '1',
          project: 'Test Project',
          date1: '03:37:22',
          date2: '03:37:22',
          date3: '03:37:22',
          date4: '03:37:22',
          date5: '03:37:22',
          date6: '03:37:22',
          date7: '03:37:22',
        },
        {
          id: '2',
          project: 'Test Project',
          date1: '03:37:22',
          date2: '03:37:22',
          date3: '03:37:22',
          date4: '03:37:22',
          date5: '03:37:22',
          date6: '03:37:22',
          date7: '03:37:22',
        },
        {
          id: '3',
          project: 'Test Project',
          date1: '03:37:22',
          date2: '03:37:22',
          date3: '03:37:22',
          date4: '03:37:22',
          date5: '03:37:22',
          date6: '03:37:22',
          date7: '03:37:22',
        },
      ],
      headers: REPORT_WEEKLY_HEADERS,
    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

</style>
